$(document).on("turbolinks:load", () => {
  const creditLimit = $("[data-id='credit-limit']").val();
  $(".credit-limit").text(formatAmount(creditLimit));
  initTotalUpdate(creditLimit);

  updateTotal(creditLimit);
});

const formatAmount = amount => {
  return parseFloat(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const calculateSelectionAmount = () => {
  let selectedValues = [];

  $.each($("input[ref='recurring_payments'][type='checkbox']:checked"), function () {
    selectedValues.push(
      parseFloat($(this)[0].attributes['amount'].value, 10)
    );
  });

  return selectedValues.reduce((acc, val) => (acc + val), 0.00);
};

const initTotalUpdate = creditLimit => {
  $("input[ref='recurring_payments'][type='checkbox']").click(() => {
    updateTotal(creditLimit);
  });
};

const updateTotal = (creditLimit) => {
  const totalAmount = calculateSelectionAmount();

  // render amount
  $("#selected-amount").text(formatAmount(totalAmount));

  // disable continue button if over $100
  ((totalAmount === 0) || (totalAmount > creditLimit)) ?
    $('#submit-recurring-payments').prop("disabled", true):
    $('#submit-recurring-payments').prop("disabled", false);
};
