$(document).on("turbolinks:load", function(){
  // Open video modal
  $("#howToVideoCover").on('click', function(){
    $("#howToVideo").attr('src', "https://www.youtube.com/embed/uZ5C44tlv48?rel=0&controls=1&showinfo=0&autoplay=1&playlist=uZ5C44tlv48");
  });

  // Close video modal
  $("#howToVideoModal").on('click', function(){
    $("#howToVideo").attr('src', '');
  });
});