$(document).on("turbolinks:load", () => {
  if (window.location.hash) {
    const hash = window.location.hash;

    $('html, body').animate({
      scrollTop :  $(hash).offset().top
    }, 2000);

    return false;
  };
});
